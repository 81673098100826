import {
  infoColor,
  inputColor,
  labelColor,
  inputBorderColor
} from "assets/jss/material-dashboard-pro-react.jsx";
import general from 'miscellaneous/general';
const themeLTR = {
  direction: "ltr",
  overrides: {
    MuiInput: {
      root: {
        color: inputColor,
        '&:before': {
          borderColor: inputBorderColor + '!important'
        }
      }
    },
    MuiFormLabel: {
      root: {
        color: labelColor
      }
    },
    MuiInputLabel: {
      shrink: {
        // transform: "translate(0, 1.5px) scale(0.9)"
        transform: "translate(0, -1.5px) scale(0.9)"
      }
    },
    MuiButton:
    { 
      outlined: {
        borderColor: "white",
        color: "white"
      }
    },
    MuiPickersBasePicker: {
      container: {
        '& .MuiPickersYear-yearSelected':{
          fontSize: 30
        },
        '& .MuiPickersToolbarText-toolbarTxt': {
          color: 'white',
          fontSize: 20
        },
        '& .MuiPickersToolbarText-toolbarBtnSelected': {
          color: 'white',
        },
        '& .MuiTypography-h4': {
          color: 'white',
          fontSize: 30
        },
        '& .MuiPickersDay-daySelected': {
          color: 'white',
          '& .MuiTypography-root': {
            fontWeight: "bold"
          }
        },
      },
    },
    MuiDialog: {
      paper:({theme})=>({
        [theme.breakpoints.up('md')]: {
          borderRadius: '16px'
        }
      })
    },
    MuiAutocomplete:{
      option:{
        padding: "10px 16px !important" ,
        "&.Mui-focused":{
          backgroundColor: "#c8eff3 !important",
        },
        "&[aria-selected=\"true\"]":{
          backgroundColor: "#00acc1 !important",
        }
      }
    },
    MuiTab:{
      root:({theme})=>({
        flexDirection: "unset",
        [theme.breakpoints.up('sm')]: {
          minWidth: "160px"
        }
      })
    }
  }
};

const themeRTL = {
  direction: "rtl",
  overrides: {
    MuiInput: {
      root: {
        color: inputColor,
        '&:before': {
          borderColor: inputBorderColor + '!important'
        }
      },
      input:{
        textAlign: "right",
        // paddingLeft: "24px",
      }
    },
    MuiFormLabel: {
      root: {
        color: labelColor,
        right: 0,
        textAlign: "right"
      }
    },
    MuiFormHelperText:{
      root:{
        textAlign: "right"
      }
    },
    MuiInputLabel: {
      root: {
        transformOrigin: "top right",
      },
      shrink: {
        // transform: "translate(0, 1.5px) scale(0.9)"
        transform: "translate(0, -1.5px) scale(0.9)"
      }
    },
    MuiButton:
    { 
      outlined: {
        borderColor: "white",
        color: "white"
      }
    },
    MuiPickersBasePicker: {
      container: {
        '& .MuiPickersYear-yearSelected':{
          fontSize: 30
        },
        '& .MuiPickersToolbarText-toolbarTxt': {
          color: 'white',
          fontSize: 20
        },
        '& .MuiPickersToolbarText-toolbarBtnSelected': {
          color: 'white',
        },
        '& .MuiTypography-h4': {
          color: 'white',
          fontSize: 30
        },
        '& .MuiPickersDay-daySelected': {
          color: 'white',
          '& .MuiTypography-root': {
            fontWeight: "bold"
          }
        },
      },
    },
    MuiDialog: {
      paper:({theme})=>({
        textAlign: "right",
        [theme.breakpoints.up('md')]: {
          borderRadius: '16px'
        }
      })
    },
    MuiSelect:{
      icon:{
        right: "unset",
        left: 0
      },
      select:{
        paddingRight: "0px!important" 
      }
    },
    MuiChip:{
      deleteIcon:{
        marginRight: "-6px",
        marginLeft: "5px"
      }
    },
    MuiAutocomplete:{
      root:{
        "&& .MuiInput-root .MuiInput-input":{
          padding: "4px 0px 4px 4px"
        },
      },
      inputRoot:{
        paddingRight: "0px!important",
        paddingLeft: "30px!important"
      },
      endAdornment:{
        left: 0,
        right: "unset"
      },
      option:{
        padding: "10px 16px !important" ,
        "&.Mui-focused":{
          backgroundColor: "#c8eff3 !important",
        },
        "&[aria-selected=\"true\"]":{
          backgroundColor: "#00acc1 !important",
        }
      }
    },
    MuiSwitch:{
      switchBase:{
        transform: "translateX(20px)",
        "&.Mui-checked":{
          transform: "translateX(0px)"
        }
      }
    },
    MuiTableCell:{
      root:{
        textAlign: "right"
      }
    },
    MuiFormControlLabel:{
      root: {
        marginRight: "-11px",
        marginLeft: "16px"
      }
    },
    MuiListItem:{
      root:{
        textAlign: "right"
      }
    },
    MuiTab:{
      root:({theme})=>({
        flexDirection: "unset",
        [theme.breakpoints.up('sm')]: {
          minWidth: "160px"
        }
      })
    },
    MuiPickersCalendarHeader:{
      labelContainer:{
        marginLeft: "auto",
        marginRight: "unset",
      }
    },
    MuiButtonBase:{
      root:{
        "&.MuiPickersArrowSwitcher-button":{
          margin : "0px 0px 0px -12px"
        }
      }
    },
    MuiInputAdornment:{
      root:{
        marginRight: "unset"
      }
    }
  }
}

export default {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920  
    }
  },
  palette: {
    primary: {
      main: infoColor
    },

  },
  typography: {
    fontFamily: ["Arimo"],
    h1: {
      fontSize: "34px", 
      fontWeight: 500, 
      marginBlockStart: "0.67em",
      marginBlockEnd: "0.67em"
    },
    h2: {fontSize:  "20px"},
    h3: {fontSize:  "19px"},
    h4: {fontSize:  "18px", fontWeight: 400},
    h5: {fontSize:  "17px"},
    h6: {fontSize:  "16px"},
    subtitle1: {fontSize:  "15px"},
    body1: {fontSize:  "15px"},
    body2: {fontSize:  "0.875rem"},
    body3: {fontSize:  "16px", fontWeight:  "bold"},
    body4: {fontSize:  "13px"},

    select: {fontSize:  "16px"},
    BUTTON: { fontSize: "16px", fontWeight: 300 },
    v2: {
      body1: {
        regular: {
          fontSize: "18px",
          lineHeight: "24px",
          fontWeight: 400,
        },
        medium: {
          fontSize: "18px",
          lineHeight: "24px",
          fontWeight: 500,
        },
        semibold: {
          fontSize: "18px",
          lineHeight: "24px",
          fontWeight: 600,
        },
        bold: {
          fontSize: "18px",
          lineHeight: "24px",
          fontWeight: 700,
        },
      },
      body2: {
        regular: {
          fontSize: "15px",
          lineHeight: "20px",
          fontWeight: 400,
        },
        medium: {
          fontSize: "15px",
          lineHeight: "20px",
          fontWeight: 500,
        },
        semibold: {
          fontSize: "15px",
          lineHeight: "20px",
          fontWeight: 600,
        },
        bold: {
          fontSize: "15px",
          lineHeight: "20px",
          fontWeight: 700,
        },
      },
      body3: {
        regular: {
          fontSize: "13px",
          lineHeight: "16px",
          fontWeight: 400,
        },
        medium: {
          fontSize: "13px",
          lineHeight: "16px",
          fontWeight: 500,
        },
        semibold: {
          fontSize: "13px",
          lineHeight: "16px",
          fontWeight: 600,
        },
        bold: {
          fontSize: "13px",
          lineHeight: "16px",
          fontWeight: 700,
        },
      },
    },
  },
  ...general.language === 'he' ? themeRTL : themeLTR
};