import React from "react";
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@mui/styles';

// Configure JSS
const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
});

function JSSRtl(props) {
  React.useLayoutEffect(() => {
    document.body.setAttribute("dir", "rtl");
  }, []);
  return <StylesProvider jss={jss}>{props.children}</StylesProvider>;
}

export default JSSRtl